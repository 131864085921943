import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnboardBenefitComponent } from './shared/shared/onboard-benefit/onboard-benefit.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { ReinsurerLoginComponent } from './signin/reinsurer-login/reinsurer-login.component';
import { LoginComponent } from './signin/login/login.component';
import { LandingPageComponent } from './signin/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    component:LandingPageComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('./insurer-routing/demo-routing/demo-routing.module').then(m => m.DemoRoutingModule)
  },
  {
    path: 'axa-demo',
    loadChildren: () => import('./insurer-routing/axa-demo/axa-demo-routing.module').then(m => m.AxaDemoRoutingModule)
  },
  {
    path: 'insurancehouse',
    loadChildren: () => import('./insurer-routing/insurancehouse-routing/insurancehouse-routing.module').then(m => m.InsuranceHouseRoutingModule)
  },
  {
    path: 'abnic',
    loadChildren: () => import('./insurer-routing/abnic-routing/abnic-routing.module').then(m => m.AbnicRoutingModule)
  },
  {
    path: 'staging',
    loadChildren: () => import('./insurer-routing/staging-routing/staging-routing.module').then(m => m.StagingRoutingModule)
  },
  {
    path: 'orienttakaful',
    loadChildren: () => import('./insurer-routing/orienttakaful-routing/orienttakaful-routing.module').then(m => m.OrienttakafulRoutingModule)
  },
  {
    path: 'qic',
    loadChildren: () => import('./insurer-routing/qic-routing/qic-routing.module').then(m => m.QicRoutingModule)
  },
  {
    path: 'dnic',
    loadChildren: () => import('./insurer-routing/dnic-routing/dnic-routing.module').then(m => m.DnicRoutingModule)
  },{
    path: 'newton',
    loadChildren: () => import('./insurer-routing/newton-routing/newton-routing.module').then(m => m.NewtonRoutingModule)
  },
  {
    path: 'dic',
    loadChildren: () => import('./insurer-routing/dic-routing/dic-routing.module').then(m => m.DicRoutingModule)
  },
  {
    path: 'qic-ri',
    loadChildren: () => import('./insurer-routing/qic-ri-routing/qic-ri-routing.module').then(m => m.QicRIRoutingModule)

  },
  {
    path: 'takafulemarat',
    loadChildren: () => import('./insurer-routing/takafulemarat-routing/takafulemarat-routing.module').then(m => m.TakafulemaratRoutingModule)

  },
  {
    path: 'staging-test',
    loadChildren: () => import('./insurer-routing/staging-test/staging-test-routing.module').then(m => m.StagingTestRoutingModule)

  },
  {
    path: 'dni',
    loadChildren: () => import('./insurer-routing/dnic-routing/dnic-routing.module').then(m => m.DnicRoutingModule)
  },
  {
    path: 'aiaw',
    loadChildren: () => import('./insurer-routing/aiaw/aiaw-routing.module').then(m => m.AiawRoutingModule)
  },
  {
    path: 'rak',
    loadChildren: () => import('./insurer-routing/rak-routing/rak-routing.module').then(m => m.RakRoutingModule)
  },
  {
    path: 'nlgi',
    loadChildren: () => import('./insurer-routing/nlgi-routing/nlgi-routing.module').then(m => m.NlgiRoutingModule)
  },
  {
    path: 'orient',
    loadChildren: () => import('./insurer-routing/orient-routing/orient-routing.module').then(m => m.OrientRoutingModule)
  },
  {
    path: 'awnic',
    loadChildren: () => import('./insurer-routing/awnic-routing/awnic-routing.module').then(m => m.AwnicRoutingModule)
  },
  {
    path:'nascore-insurer',
    loadChildren: () => import('./insurer-routing/nascore-routing/nascore-routing.module').then(m=>m.NascoreRoutingModule)
  },
  {
    path:'dohatakaful',
    loadChildren: () => import('./insurer-routing/dohatakaful-routing/dohatakaful-routing.module').then(m=>m.DohaTakafulRoutingModule)
  },
  {
    path: 'medgulf-insurer',
    loadChildren: () => import('./insurer-routing/medgulf-insurer-routing/medgulf-insurer-routing-routing').then(m => m.MedgulfInsurerRoutingModule)
  },
  {
    path: 'axa-dni',
    loadChildren:()=>import('./insurer-routing/axa-dni-routing/axa-dni-routing-routing.module').then(m=>m.AxaDniRoutingRoutingModule)

  },
  {
    path: 'watania-takaful',
    loadChildren:()=>import('./insurer-routing/wataniatakaful-routing/wataniatakaful-routing.module').then(m=>m.WataniaTakafulRoutingModule)

  },
  {
    path: 'fidelityinsurance',
    loadChildren:()=>import('./insurer-routing/fidelity-insurance/fidelity-insurance-routing.module').then(m=>m.FidelityInsuranceRoutingModule)

  },
  {
    path: 'onboard',
    component:OnboardBenefitComponent
  },
  {
    path:'health-check',
    component:HealthCheckComponent
  },
  {
    path:':reinsurer/login',
    component:ReinsurerLoginComponent
  },
  {
    path:':reinsurer/:insurer/login',
    component:LoginComponent
  } ,

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
